import trim from 'licia/trim'
import each from 'licia/each'
import decodeUriComponent from 'licia/decodeUriComponent'
import rmCookie from 'licia/rmCookie'
import isNative from 'licia/isNative'
import contain from 'licia/contain'
import { XhrRequest, FetchRequest } from '../lib/request'
import chobitsu from '../lib/chobitsu'

export function deleteCookies(params) {
  rmCookie(params.name)
}

export function getCookies() {
  const cookies = []

  const cookie = document.cookie
  if (trim(cookie) !== '') {
    each(cookie.split(';'), function (value) {
      value = value.split('=')
      const name = trim(value.shift())
      value = decodeUriComponent(value.join('='))
      cookies.push({
        name,
        value,
      })
    })
  }

  return { cookies }
}

const resTxtMap = new Map()

export const enableIFrameNetwork = function (win) {
  const winXhrProto = win.XMLHttpRequest.prototype

  const origSend = winXhrProto.send
  const origOpen = winXhrProto.open
  const origSetRequestHeader = winXhrProto.setRequestHeader

  const network = chobitsu.domain('Network')

  winXhrProto.open = function (method, url) {

    if (!isValidUrl(url)) {
      return origOpen.apply(this, arguments)
    }

    const xhr = this

    const req = ((xhr).chobitsuRequest = new XhrRequest(
      xhr,
      method,
      url
    ))

    req.on('send', (id, data) => {
      const request = {
        method: data.method,
        url: data.url,
        headers: data.reqHeaders,
      }
      if (data.data) {
        request.postData = data.data
      }
      network.emit('requestWillBeSent', {
        requestId: id,
        type: 'XHR',
        request,
        timestamp: data.time / 1000,
      })
    })
    req.on('headersReceived', (id, data) => {
      network.emit('responseReceivedExtraInfo', {
        requestId: id,
        blockedCookies: [],
        headers: data.resHeaders,
      })
    })
    req.on('done', (id, data) => {
      network.emit('responseReceived', {
        requestId: id,
        type: 'XHR',
        response: {
          status: data.status,
        },
        timestamp: data.time / 1000,
      })
      resTxtMap.set(id, data.resTxt)
      network.emit('loadingFinished', {
        requestId: id,
        encodedDataLength: data.size,
        timestamp: data.time / 1000,
      })
    })

    xhr.addEventListener('readystatechange', function () {
      switch (xhr.readyState) {
        case 2:
          return req.handleHeadersReceived()
        case 4:
          return req.handleDone()
      }
    })

    origOpen.apply(this, arguments)
  }

  winXhrProto.send = function (data) {
    const req = (this).chobitsuRequest
    if (req) req.handleSend(data)

    origSend.apply(this, arguments)
  }

  winXhrProto.setRequestHeader = function (key, val) {
    const req = (this).chobitsuRequest
    if (req) {
      req.handleReqHeadersSet(key, val)
    }

    origSetRequestHeader.apply(this, arguments)
  }

  let isFetchSupported = false
  if (win.fetch) {
    isFetchSupported = isNative(win.fetch)
  }
  // #2 Probably not a fetch polyfill
  if (!isFetchSupported && navigator.serviceWorker) {
    isFetchSupported = true
  }
  if (!isFetchSupported) return

  const origFetch = win.fetch

  win.fetch = function (...args) {
    const req = new FetchRequest(...args)
    req.on('send', (id, data) => {
      const request = {
        method: data.method,
        url: data.url,
        headers: data.reqHeaders,
      }

      if (data.data) {
        request.postData = data.data
      }

      network.emit('requestWillBeSent', {
        requestId: id,
        type: 'Fetch',
        request,
        timestamp: data.time / 1000,
      })
    })
    req.on('done', (id, data) => {
      network.emit('responseReceived', {
        requestId: id,
        type: 'Fetch',
        response: {
          status: data.status,
          headers: data.resHeaders,
        },
        timestamp: data.time / 1000,
      })
      resTxtMap.set(id, data.resTxt)
      network.emit('loadingFinished', {
        requestId: id,
        inIframe: 1,
        encodedDataLength: data.size,
        timestamp: data.time / 1000,
      })
    })

    const fetchResult = origFetch(...args)
    req.send(fetchResult)

    return fetchResult
  }
}

export function getResponseBody(params) {
  return {
    base64Encoded: false,
    body: resTxtMap.get(params.requestId),
  }
}

function isValidUrl(url) {
  return !contain(url, '__chobitsu-hide__=true')
}