import Tool from '../DevTools/Tool'
import $ from 'licia/$'
import toStr from 'licia/toStr'
import isFn from 'licia/isFn'
import Emitter from 'licia/Emitter'
import isStr from 'licia/isStr'
import isRegExp from 'licia/isRegExp'
import uncaught from 'licia/uncaught'
// import trim from 'licia/trim'
import upperFirst from 'licia/upperFirst'
import isHidden from 'licia/isHidden'
import isNull from 'licia/isNull'
import extend from 'licia/extend'
import evalCss from '../lib/evalCss'
import Settings from '../Settings/Settings'
import LunaObjectViewer from 'luna-object-viewer'
import LunaModal from 'luna-modal'
import { classPrefix as c } from '../lib/util'

uncaught.start()

export default class State extends Tool {
  constructor({ name = 'state' } = {}) {
    super()

    Emitter.mixin(this)

    this.name = name
    this._selectedLog = null
  }
  init($el, container) {
    super.init($el)
    this._container = container

    this._appendTpl()

    this._initCfg()

    // this._exposeLogger()
    this._bindEvent()

    this.$event =
      window.$event || (window.parent ? window.parent.$event : undefined)
    if (this.$event) {
      this.eid = this.$event.$on('__render-state-change', async (params) => {
        const { fileName } = params
        const comp = (window.runnerContentWindow || window.AliLowCodeEngine?.project?.simulatorHost.contentWindow)?.componentInstances?.[fileName];
        if (window.consoleCurrentFileName === fileName) {
          this.data = {
            state: comp?.state,
            props: comp?.props,
          }
          this._renderObj()
        }
        // }
      })
    }
  }
  show() {
    super.show()
    this._handleShow()
  }
  overrideState() {
    // const origConsole = (this._origConsole = {})
    // const winConsole = window.AliLowCodeEngine?.project?.simulatorHost?.contentWindow?.console || window.console

    // CONSOLE_METHOD.forEach((name) => {
    //   let origin = (origConsole[name] = noop)
    //   if (winConsole[name]) {
    //     origin = origConsole[name] = winConsole[name].bind(winConsole)
    //   }

    //   winConsole[name] = (...args) => {
    //     this[name](...args)
    //     origin(...args)
    //   }
    // })

    return this
  }
  restoreState() {
    return this
  }
  filter(filter) {
    const $filterText = this._$filterText

    if (isStr(filter)) {
      $filterText.text(filter)
    } else if (isRegExp(filter)) {
      $filterText.text(toStr(filter))
    } else if (isFn(filter)) {
      $filterText.text('ƒ')
    }
  }
  destroy() {
    // this._logger.destroy()
    super.destroy()

    this._container.off('show', this._handleShow)

    if (this._style) {
      evalCss.remove(this._style)
    }
    this.restoreState()
    this._rmCfg()
  }
  _handleShow = () => {
    if (isHidden(this._$el.get(0))) return
    // this._renderObj()
  }
  _appendTpl() {
    const $el = this._$el

    this._style = evalCss(require('./State.scss'))
    $el.append(
      c(`
      <div class="state">
        <span class="icon-clear clear-state"></span>
        <span class="level" data-level="all">All</span>
        <span class="level active" data-level="state">State</span>
        <span class="level" data-level="props">Props</span>
        <span class="filter-text"></span>
        <span class="icon-filter filter"></span>
        <span class="icon-copy icon-disabled copy"></span>
      </div>
      <div class="state-container"></div>
    `)
    )

    extend(this, {
      _$control: $el.find(c('.state')),
      _$state: $el.find(c('.state-container')),
      _$filterText: $el.find(c('.filter-text')),
    })
  }

  _renderHtml(html, cache = true) {
    if (cache && html === this._lastHtml) return
    this._lastHtml = html
    this._$state.html(html)
    // Need setTimeout to make it work
    setTimeout(() => (this._$state.scrollTop = 0), 0)
  }

  _renderObj() {
    let val = this.data?.state || {}
    try {
      if (isStr(val)) {
        val = JSON.parse(val)
      }
      /* eslint-disable no-empty */
    } catch (e) {}
    if (!this.objViewer) {
      // Using cache will keep binding events to the same elements.
      this._renderHtml(`<ul class="${c('json')}"></ul>`, false)
      const objViewer = new LunaObjectViewer(
        this._$state.find('.eruda-json').get(0),
        {
          unenumerable: false,
          prototype: false,
          accessGetter: true,
        }
      )
      this.objViewer = objViewer
    }
    this.objViewer.set(val)
  }

  // _exposeLogger() {
  //   const logger = this._logger
  //   const methods = ['html'].concat(CONSOLE_METHOD)

  //   methods.forEach(
  //     (name) =>
  //       (this[name] = (...args) => {
  //         logger[name](...args)
  //         this.emit(name, ...args)

  //         return this
  //       })
  //   )
  // }
  _bindEvent() {
    const container = this._container
    const $control = this._$control

    // const logger = this._logger
    // const config = this.config

    $control
      .on('click', c('.clear-state'), () => {
        // 清空State
        // logger.clear(true)
      })
      .on('click', c('.level'), function () {
        let level = $(this).data('level')
        if (level === 'all') {
          level = ['verbose', 'info', 'warning', 'error']
        }
        // logger.setOption('level', level)
      })
      .on('click', c('.filter'), () => {
        LunaModal.prompt('Filter').then((filter) => {
          if (isNull(filter)) return
          this.filter(filter)
        })
      })
      .on('click', c('.copy'), () => {
        this._selectedLog.copy()
        container.notify('Copied')
      })
    container.on('show', this._handleShow)
  }
  _rmCfg() {
    const cfg = this.config

    const settings = this._container.get('settings')
    if (!settings) return

    settings.remove(cfg, 'overrideState').remove(upperFirst(this.name))
  }
  _initCfg() {
    const container = this._container

    const cfg = (this.config = Settings.createCfg(this.name, {
      overrideState: true,
      maxLogNum: 'infinite',
    }))

    cfg.on('change', (key, val) => {
      // const logger = this._logger
      switch (key) {
        case 'overrideState':
          return val ? this.overrideState() : this.restoreState()
      }
    })

    const settings = container.get('settings')
    if (!settings) return

    settings
      .text(upperFirst(this.name))
      .switch(cfg, 'overrideState', 'Override State')
      .separator()
  }
}
